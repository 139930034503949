import React from "react";

// reactstrap components
import {
  Card,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import ColorNavbar from "components/Navbars/ColorNavbar.js";

function Discover() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("discover");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("discover");
    };
  });
  return (
    <>
      <ColorNavbar />
      <div className="wrapper">
        <div className="wrapper">
          <div className="main">
            <div className="section">
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="6" xs="12">
                    <h2 className="discover-title">
                      <small>Some of my latest art</small>
                    </h2>
                  </Col>
                </Row>
                <Row className="items-row">
                  <Col  md="4" >
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/lake-erie-galaxy.jpg")}
                          />
                        </a>
                        
                      </div>
                    </Card>
                  </Col>
                  <Col md="4" >
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/blizzard-witch.png")}
                          />
                        </a>
                        
                      </div>
                    </Card>
                  </Col>
                  <Col className="mr-auto" sm="4">
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/road-to-valhalla.png")}
                          />
                        </a>
                        
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row className="items-row">
                  <Col  md="4">
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/font-design.png")}
                          />
                        </a>
                        
                      </div>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/band-tat.jpg")}
                          />
                        </a>
                        
                      </div>
                    </Card>
                  </Col>
                  <Col className="mr-auto d-sm-block" md="4">
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/at-peace.jpg")}
                          />
                        </a>
                        
                      </div>
                    </Card>
                  </Col>
                </Row>
                <Row className="items-row">
                  <Col  md="4" >
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/body-fruit.jpg")}
                          />
                        </a>
                        
                      </div>
                    </Card>
                  </Col>
                  <Col md="4" >
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/clan-logo.jpg")}
                          />
                        </a>
                        
                      </div>
                    </Card>
                  </Col>
                  <Col className="mr-auto" md="4" >
                    <Card className="card-plain text-center">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img-rounded img-responsive imagezoom"
                            src={require("assets/img/sections/tlm-tracks.jpg")}
                          />
                        </a>
                      </div>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Discover;
