import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/css/paper-kit.css";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Index from "views/Index.js";
// import Presentation from "views/Presentation.js";
import Error404 from "views/examples/Error404.js";
import Error422 from "views/examples/Error422.js";
import Error500 from "views/examples/Error500.js";
// Main Styling
import './App.css';
import './index.css';
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/*" render={(props) => <Index {...props} />} />
      <Route path="/index" render={(props) => <Index {...props} />} />
      <Route path="/error-404" render={(props) => <Error404 {...props} />} />
      <Route path="/error-422" render={(props) => <Error422 {...props} />} />
      <Route path="/error-500" render={(props) => <Error500 {...props} />} />
      <Redirect to="/#/index" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
