/*eslint-disable*/
import React from "react";
import "assets/css/paper-kit.css";
// reactstrap components
import { Container, Row, Button } from "reactstrap";

// core components

function FooterBlack() {
  return (
    <>
      <footer className="footer footer-black footer-white">
        <Container>
          <Row>
            <nav className="footer-nav">
              <a id="gitbutton" className="contactme"href="mailto:amycheck8715@gmail.com">
                Contact me for services and inquiries!
              </a>
              <Button className="btn-just-icon" color="linkedin" href="https://linkedin.com/in/amycheck/">
                <i className="fa fa-linkedin"></i>
              </Button>
              <Button className="btn-just-icon" color="github" href="https://github.com/AmyNicole8715">
                <i className="fa fa-github-alt"></i>
              </Button>
            </nav>
            <div className="credits ml-auto">
             
              <span className="copyright">
                © {new Date().getFullYear()}
                , made by Amy
              </span>
            </div>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterBlack;
