import React from "react";

// reactstrap components

// core components

function IndexHeader() {
  return (
    <>
      <div
        className="index-background page-header"
        style={{
          backgroundImage: "url(" + require("assets/img/cover1.jpg") + ")",
        }}
      >
        <div className="filter" />
        <div className="content-center">
          <div className="motto">
            <h1 className="index-title">Amy Check</h1>
            <h2 className="presentation-subtitle text-center">
                Software Development, Web Development, and Graphic Design
              </h2>
              <p className="skill-summary">
                  I am experienced with C#, Ruby, Ruby on Rails, MVC,<br></br> 
                  React, RSpec, TDD, OOP, HTML, CSS, JavaScript, .NET,<br></br>
                  Git, Bootstrap, SQL, and eager to continue to learn.<br></br>
                  I have additional experience in graphic design. 
              </p>
          </div>
        </div>
      </div>
    </>
  );
}

export default IndexHeader;
